import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  Nav,
  FormInput,
  Button,
  Loader,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Modal,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";

import Folder from "../../components/search/Folder";
import Parts from "../../components/search/Parts";
import ShowOptionsNGRA from "../../components/general/ShowOptionsNGRA-v2";
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: "",
      licensePlate: "",
      sinister: "",
      client: "",
      folders: [],
      isLoad: false,
      tabsIndexFolder: 0,
      menuTabs: [],
      tabsIndexMenu: 0,
      network: null,
      group: null,
      region: null,
      agency: null,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.setState({
      menuTabs:
        this.context.network === null || this.context.group === 1
          ? ["Informations", "PQE / PRE web"]
          : ["Informations"],
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  searchFolder = () => {
    if (
      this.state.reference ||
      this.state.licensePlate ||
      this.state.sinister ||
      this.state.client
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("search/folder", {
          data: {
            reference: this.state.reference,
            licensePlate: this.state.licensePlate,
            sinister: this.state.sinister,
            client: this.state.client,
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState({
              isLoad: false,
              folders: res.data.data,
            });
          } else {
            this.setState(
              {
                isLoad: false,
                folders: [],
              },
              () => alert("Pas de dossier")
            );
          }
        });
      });
    } else {
      alert("Veuillez saisir une valeur");
    }
  };

  render() {
    return (
      <>
        <Nav className="mb-10 mb-30-xl" title="Recherche dossier" />
        <FadeIn>
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={(data) =>
              this.setState({
                network: data.network,
                group: data.group,
                region: data.region,
                agency: data.agency,
              })
            }
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
          />
          <Card title="Recherche" className="mb-10 mb-30-xl">
            <Row>
              <Col xs={12} md={2} className={"mt-10 mt-30-xl"}>
                <FormInput
                  value={this.state.reference}
                  name={"reference"}
                  onChange={this.handleInputChange}
                  title="Numéro de dossier"
                />
              </Col>
              <Col xs={12} md={2} className={"mt-10 mt-30-xl"}>
                <FormInput
                  value={this.state.sinister}
                  name={"sinister"}
                  onChange={this.handleInputChange}
                  title="Numéro de sinistre"
                />
              </Col>
              <Col xs={12} md={2} className={"mt-10 mt-30-xl"}>
                <FormInput
                  value={this.state.licensePlate}
                  name={"licensePlate"}
                  onChange={this.handleInputChange}
                  title="Immatriculation"
                />
              </Col>
              <Col xs={12} md={3} className={"mt-10 mt-30-xl"}>
                <FormInput
                  value={this.state.client}
                  name={"client"}
                  onChange={this.handleInputChange}
                  title="Nom / E-mail assuré"
                />
              </Col>
              <Col xs={12} md={3} className="d-flex mt-10 mt-30-xl">
                <Button
                  className="mt-auto"
                  text="Rechercher"
                  disabled={
                    this.state.reference ||
                    this.state.licensePlate ||
                    this.state.sinister ||
                    this.state.client
                      ? false
                      : true
                  }
                  onClick={this.searchFolder}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </Col>
            </Row>
          </Card>

          {this.state.folders ? (
            <TabsCustom className={"mt-30"}>
              <TabsCustomHeader>
                {this.state.folders.map((element, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    title={`Dossier nº${element.reference} - ${
                      element.vehicule ? element.vehicule.immat : ""
                    }`}
                    active={this.state.tabsIndexFolder === idx}
                    onClick={(e) => {
                      this.setState({ tabsIndexFolder: idx });
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                {this.state.folders.map((element, idx) => (
                  <TabsCustomItem
                    active={this.state.tabsIndexFolder === idx}
                    key={idx}
                  >
                    <TabsCustom>
                      <TabsCustomHeader>
                        {this.state.menuTabs.map((element, idx) => (
                          <TabsCustomMenu
                            key={idx}
                            title={element}
                            active={this.state.tabsIndexMenu === idx}
                            onClick={(e) => {
                              this.setState({ tabsIndexMenu: idx });
                            }}
                          />
                        ))}
                      </TabsCustomHeader>
                      <TabsCustomBody noCard>
                        <>
                          <TabsCustomItem
                            active={this.state.tabsIndexMenu === 0}
                          >
                            <Folder folder={element} />
                          </TabsCustomItem>
                          <TabsCustomItem
                            active={this.state.tabsIndexMenu === 1}
                          >
                            <Parts
                              parts={
                                element.parts && element.parts.length
                                  ? element.parts
                                  : []
                              }
                            />
                          </TabsCustomItem>
                        </>
                      </TabsCustomBody>
                    </TabsCustom>
                  </TabsCustomItem>
                ))}
              </TabsCustomBody>
            </TabsCustom>
          ) : null}
        </FadeIn>
        <Modal
          show={this.state.openModal}
          onClose={() =>
            this.setState({
              openModal: false,
              dataModal: null,
            })
          }
          title={"Commentaires"}
        >
          {this.state.dataModal &&
            this.state.dataModal.map((row, idx) => <p key={idx}>{row}</p>)}
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
