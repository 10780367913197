import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Status,
} from "ui-kit-ck-consultant";

import { getCurrencyValue, getPercentageValue } from "../../utils/general";

export default class Details extends Component {
  render() {
    return (
      <Card
        title="Détails"
        className="mb-10 mb-20-xl"
        classNameChildren="overflow-x-auto"
      >
        <Table className="m-auto">
          <Thead>
            <Tr>
              <Th>Volume total cloturé</Th>
              <Th>Montant moyen HT</Th>
              <Th>Montant total HT</Th>
              <Th>Délai mission - dépôt (j)</Th>
              <Th>Taux cloturé</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{this.props.data.total.countClosed}</Td>
              <Td>{getCurrencyValue(this.props.data.total.average)}</Td>
              <Td>{getCurrencyValue(this.props.data.total.price)}</Td>
              <Td>{parseInt(this.props.data.total.delai * 100) / 100}</Td>
              <Td>
                <Status
                  className="w-100"
                  text={getPercentageValue(this.props.data.total.taux)}
                />
              </Td>
            </Tr>
          </Tbody>
          <Thead>
            <Tr>
              <Th>Volume R cloturé</Th>
              <Th>Coût moyen HT</Th>
              <Th>Coût total HT</Th>
              <Th>Délai mission - dépôt (j)</Th>
              <Th>Taux R cloturé</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{this.props.data.R.countClosed}</Td>
              <Td>{getCurrencyValue(this.props.data.R.average)}</Td>
              <Td>{getCurrencyValue(this.props.data.R.price)}</Td>
              <Td>{parseInt(this.props.data.R.delai * 100) / 100}</Td>
              <Td>
                <Status
                  className="w-100"
                  text={getPercentageValue(this.props.data.R.taux)}
                />
              </Td>
            </Tr>
          </Tbody>
          <Thead>
            <Tr>
              <Th>Volume NR cloturé</Th>
              <Th>VRADE moyenne HT</Th>
              <Th>VRADE totale HT</Th>
              <Th>Délai mission - dépôt (j)</Th>
              <Th>Taux NR cloturé</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{this.props.data.NR.countClosed}</Td>
              <Td>{getCurrencyValue(this.props.data.NR.average)}</Td>
              <Td>{getCurrencyValue(this.props.data.NR.price)}</Td>
              <Td>{parseInt(this.props.data.NR.delai * 100) / 100}</Td>
              <Td>
                <Status
                  className="w-100"
                  text={getPercentageValue(this.props.data.NR.taux)}
                />
              </Td>
            </Tr>
          </Tbody>
          <Thead>
            <Tr>
              <Th colSpan={2}>Taux VEI</Th>
              <Th colSpan={3}>Taux préjudice esthétique</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td colSpan={2}>{getPercentageValue(this.props.data.other.VEI)}</Td>
              <Td colSpan={3}>{getPercentageValue(this.props.data.other.aestheticPrevention)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Card>
    );
  }
}
