import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Nav,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  Loader,
  Button,
  PaginationClass,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import VVR from "../../../components/specialties/VVNR_VVR/VVR";
import VVNR from "../../../components/specialties/VVNR_VVR/VVNR";
import ModalVVNR from "../../../components/specialties/VVNR_VVR/ModalVVNR";

import VVNR_MAIF from "./VVNR_MAIF";

export class VVNR_VVR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      tabsMenu: ["VVNR", "VVR"],
      tabsIndex: 0,
      // data
      labelsVVNR_NR: [],
      labelsVVR_R: [],
      labelsVVR_NR: [],
      dataVVNR: [],
      dataVVR: [],
      dataList: [],
      dataChart: [],
      // Modal VVNR
      isModalVVNR: false,
      //Pagination
      activePage: 1,
      tabLength: 10,
      total: 0,
    };
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "specialties/VVNR_VVR/count-VVNR",
      "specialties/VVNR_VVR/pagination-VVNR",
      this.state.tabLength
    );
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        company: data.company,
      },
      () => {
        this.getData();
        this.initSearch();
      }
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("specialties/VVNR_VVR/get-data", {
          data: this.getDataRequest(),
        })
          .then((res) => {
            if (res.data.success) {
              this.setState(
                {
                  labelsVVNR_NR: res.data.labelsVVNR_NR,
                  labelsVVR_R: res.data.labelsVVR_R,
                  labelsVVR_NR: res.data.labelsVVR_NR,
                  dataVVNR: res.data.dataVVNR,
                  dataVVR: res.data.dataVVR,
                },
                this.getDataList
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                  labelsVVNR_NR: [],
                  labelsVVR_R: [],
                  labelsVVR_NR: [],
                  dataVVNR: [],
                  dataVVR: [],
                  dataList: [],
                },
                () => alert("Une erreur est survenue")
              );
            }
          })
          .catch(() => {
            this.setState(
              {
                isLoad: false,
                labelsVVNR_NR: [],
                labelsVVR_R: [],
                labelsVVR_NR: [],
                dataVVNR: [],
                dataVVR: [],
                dataList: [],
              },
              () => alert("Une erreur est survenue")
            );
          });
      }
    );
  };

  getDataList = () => {
    axiosConfig("specialties/VVNR_VVR/get-VVNR", {
      data: this.getDataRequest(),
    }).then((res) => {
      if (res.data.success) {
        this.setState({
          isLoad: false,
          dataChart: res.data.dataChart,
        });
      } else {
        this.setState({ isLoad: false, dataChart: [] });
      }
    });
  };

  getDataRequest = () => {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      company: this.state.company,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      this.PaginationClass.init(
        axiosConfig,
        this.getDataRequest(),
        (result) => {
          if (result.success === true) {
            this.setState({ total: result.total }, () => {
              this.PaginationClass.get(
                axiosConfig,
                this.getDataRequest(),
                (result) => {
                  this.setData(result);
                }
              );
            });
          }
        }
      );
    });
  };

  handlePageChange = (activePage) => {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(
          axiosConfig,
          this.getDataRequest(),
          (result) => {
            if (result.success === true) {
              this.setState({ isLoad: false }, () => {
                this.setData(result);
              });
            }
          }
        );
      })
    );
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ dataList: result.tabData });
    } else {
      this.setState({ dataList: [] });
    }
  }

  onSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="VVNR / VVR" className="mb-10 mb-20-xl">
            {this.state.tabsIndex === 0 ? (
              <Button
                text="Formulaire notation VVNR"
                outline
                onClick={() => this.setState({ isModalVVNR: true })}
              />
            ) : null}
          </Nav>
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isCompany={true}
            isUser={true}
            isDateStart={true}
            isDateEnd={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            userType={[1, 2, 3]}
            isDefaultAllUser={true}
          />
          <TabsCustom>
            <TabsCustomHeader className={"mb-10 mb-20-xl"}>
              {this.state.tabsMenu.map((element, key) => (
                <TabsCustomMenu
                  noCard
                  disabled={this.props.isLoader}
                  key={key}
                  title={element}
                  active={this.state.tabsIndex === key}
                  onClick={(e) => {
                    this.setState({ tabsIndex: key });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={true}>
                {this.state.tabsIndex === 0 ? (
                  <>
                    {this.state.company === 8 ? (
                      <VVNR_MAIF
                        network={this.state.network}
                        group={this.state.group}
                        region={this.state.region}
                        agency={this.state.agency}
                        user={this.state.user}
                        dateStart={this.state.dateStart}
                        dateEnd={this.state.dateEnd}
                        company={this.state.company}
                      />
                    ) : (
                      <VVNR
                        labelsNR={this.state.labelsVVNR_NR}
                        data={this.state.dataVVNR}
                        dateEnd={this.state.dateEnd}
                        dataChart={this.state.dataChart}
                        dataList={this.state.dataList}
                        getDataList={() => {
                          this.getDataList();
                          this.initSearch();
                        }}
                        //Pagination
                        activePage={this.state.activePage}
                        tabLength={this.state.tabLength}
                        total={this.state.total}
                        onPageChange={this.handlePageChange}
                        onSortClick={(order) => this.onSortClick(order)}
                      />
                    )}
                  </>
                ) : (
                  <VVR
                    labelsR={this.state.labelsVVR_R}
                    labelsNR={this.state.labelsVVR_NR}
                    data={this.state.dataVVR}
                    dateEnd={this.state.dateEnd}
                  />
                )}
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
        </FadeIn>
        <ModalVVNR
          show={this.state.isModalVVNR}
          onClose={() =>
            this.setState({ isModalVVNR: false }, this.getDataList)
          }
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}

export default VVNR_VVR;
